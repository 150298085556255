import './App.css';
import SVG from 'react-inlinesvg';

const taglines = [
  "Developer extraordinaire",
  "Coding wizard",
  "Programming ninja",
  "Engineering rockstar",
  "Coding GOAT",
  "Programming Guru",
  "Makes top brews",
]

function App() {
  return (
    <div className="container">
      <h1>Todd Francis</h1>
      <h2 className="tagline">{taglines[Math.floor(Math.random() * taglines.length)]}</h2>
      <div className="content">
        <div className="block">
          <h3>I can do:</h3>
          <ul className="do-list">
            <li>Golang</li>
            <li>JavaScript (vue/react)</li>
            <li>AWS</li>
            <li>PHP</li>
            <li>SQL</li>
          </ul>
        </div>
        <div className="block2">
          <h3 style={{ marginBottom: "20px" }}>I can be found on:</h3>
          <ul className="found-list">
            <li>
              <a href="https://github.com/Toddish" target="_blank" rel="noreferrer">
                <SVG src="/github.svg" title="Github" />
              </a>
            </li>
            <li>
              <a href="mailto:todd@toddish.co.uk">
                <SVG src="/email.svg" title="Email" />
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/in/todd-francis-27270b2a" target="_blank" rel="noreferrer">
                <SVG src="/linkedin.svg" title="LinkedIn" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default App;
